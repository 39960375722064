.blue-button-white-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #474EFF;
    width: 152px;
    height: 48px;
    border-radius: 34px;
    transition: all 500ms;
    border: none !important;
    

    .button-text-white-bg {
        color: #ffffff;
        font-size: 14px;
        font-weight: 400;
        font-family: 'Unbounded';
        text-align: center;
        transition: color 500ms;
        

    }

}

.blue-button-white-bg:hover {
    cursor: pointer;   
    transition: all 500ms;


    .button-text-white-bg{
       // font-weight: 600;
        transition: color 500ms;
        color: #000000;

    }
}