.unleash-potential-main{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 208px 0px 208px 0px;
    background-image: url('../images/webp/diamond2_edited.webp');
    background-size: contain;
    background-position: center; 
    background-repeat: no-repeat;


    h3{
        font-size: 50px;
        line-height: 72px;
        text-align: center;
        font-weight: 400;
    }

    .button-div{
        margin: 42px 0px 42px 0px;
    }
    
    p{  font-family: 'satoshi', serif;
        margin: 42px 0px 42px 0px;
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
    }

    .stars-section{
        width: 100%;
        display: grid;
        grid-template-columns: 35% 1fr 35%;

        .col-one{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            grid-column: 1/2;
        }

        .col-two{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            grid-column: 2/3;
        }

        .col-three{
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            grid-column: 3/4;
        }

        p{  
            font-family: 'Unbounded';
            text-align: center;
            font-size: 13px;
            line-height: 17px;
            font-weight: 400;
            margin-top: 15px;
            color: #BBBBBB;
        }
    }
}

@media (max-width: 1024px){
    .unleash-potential-main{
        background-size: auto;
        padding: 100px 0px 100px 0px;
        margin: 100px 0px 100px 0px;
    }

    .unleash-potential-main h3{
        font-size: 36px;
        line-height: 42px;
    }

    .unleash-potential-main .stars-section p {
        font-size: 12px;
    }
}

@media (max-width: 768px){
    .unleash-potential-main  {
        margin: 50px 0px 50px 0px;
        padding: 50px 0px 50px 0px;
    }

    .unleash-potential-main h3 {
        font-size: 20px;
        line-height: 27px;
    }

    .unleash-potential-main h3 br{
        display: none;
    }

    .unleash-potential-main p{
        font-size: 15px;
    }

    .unleash-potential-main .stars-section .col-one,
    .unleash-potential-main .stars-section .col-two,
    .unleash-potential-main .stars-section .col-three{
        grid-column: 1/4;

        img{
            max-width: 90px;
        }
    }

}