.icon-frame {
  border: 1px solid #474EFF;
  border-radius: 100px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.icon-frame .icon-title {
  font-size: 41px;
  font-weight: 300;
  line-height: 51px;
  color: #474EFF;
  margin-left: 15px;
}

@media (max-width: 1024px) {
  .icon-frame img {
    width: 24px;
  }
  .icon-frame .icon-title {
    font-size: 30px;
    line-height: 40px;
  }
}
@media (max-width: 768px) {
  .icon-frame img {
    width: 15px;
  }
  .icon-frame .icon-title {
    font-size: 16px;
    line-height: 26px;
  }
}/*# sourceMappingURL=IconFrame.css.map */