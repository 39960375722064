$primary: #ffffff;
$secondary: #474EFF;
$text: #0F0F0F;
$bg-color: #070708;
$content-desktop: 1636px;


@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@200;300;400;500;600;700;800;900&display=swap');
@font-face {
    font-family: 'satoshi';
    src: url('./fonts/Satoshi-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'satoshi';
    src: url('./fonts/Satoshi-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'satoshi';
    src: url('./fonts/Satoshi-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'satoshi';
    src: url('./fonts/Satoshi-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'satoshi';
    src: url('./fonts/Satoshi-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
  }


*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html{
    scroll-behavior: smooth;
}

body{
    background-color: $bg-color;
    font-family: 'Unbounded';
    color: $primary;
    font-size: 16px;
    overflow-x: hidden;
}

.App{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
    overflow-x: hidden;

    .App-header,
    .App-main,
    .App-footer{
        max-width: $content-desktop;
        min-width: 85%;

    }

    .App-max-width{
        width: 100vw;
    }

    .App-header{
        position: absolute;
        z-index: 100;
    }
}

a{
    text-decoration: none;
}

.contact-popup{
    width: 0vw;
    height: 0vh;
    overflow: hidden;
    position: fixed;
    right: -100%;
    z-index: 0;
    transition: all 500ms;
}


.contact-popup-active{
    height: 100vh;
    width: 100vw;
    z-index: 999;
    right: 0;
}

@media (max-width: 1700px){
    .App-header,
    .App-main,
    .App-footer{
        max-width: 1450px !important;
    }
}

@media (max-width: 1500px){
    .App-header,
    .App-main,
    .App-footer{
        max-width: 1250px !important;
    }
}

@media (max-width: 1300px){
    .App-header,
    .App-main,
    .App-footer{
        max-width: 1024px !important;
    }
}

@media (max-width: 1024px){
    .App-header,
    .App-main,
    .App-footer{
        max-width: 768px !important;
    }       
}

@media (max-width: 768px){
    .App-header,
    .App-main,
    .App-footer{
        max-width: 90vw !important;
    }  
}



