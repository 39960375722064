.cookie-settings {
  position: fixed;
  left: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  padding: 1%;
  background-color: #00000099;
  backdrop-filter: blur(10px);

  h2{
    font-size: 24px;
    line-height: 30px;
  }

  p {
    font-size: 14px;
    line-height: 20px;
  }

  button {
    padding: 5px;
    margin-right: 10px;
    margin-top: 10px;
    background-color: #474EFF;
    color: #ffffff;
  }

  button:hover {
    cursor: pointer;
  }
}