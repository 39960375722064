.we-are-all-main {
    display: grid;
    grid-template-columns: 57% 43%;
    background-image: url('../images/webp/weAreAllBg_edited.webp');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 500px;
    padding-top: 220px;
    padding-bottom: 220px;


    .we-are-all-left {
        grid-column: 1/2;

        h3 {
            font-size: 50px;
            font-weight: 400;
            line-height: 72px;
        }

        p {
            font-family: 'satoshi', serif;
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
            margin-top: 30px;
        }
    }

    .we-are-all-right-one {
        grid-column: 2/3;
        line-height: 30px;
        font-size: 16px;
        font-weight: 300;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        flex-wrap: wrap;
        flex-direction: column;
        gap: 20px;

        .cytat{
            width: 60%;
            .cudzyslow-ico {
                display: inline;
                padding-right: 10px;
            }
    
            .cudzyslow-text {
                display: inline;
            }
        }


        .the-boss {
            display: flex;
            gap: 20px;
            justify-content: flex-start;
            align-items: flex-end;
            width: 60%;
    
            .boss-foto {
             display: inline;
    
                img {
                    max-width: 100px;
                }
            }
    
            .boss-text {
               display: inline;
               p{
                font-size: 18px;
                font-weight: 300px;
                line-height: 29px;
               }
               .blue-text{
                color: #474EFF;
                font-size: 13px;

               }
            }
        }
        .button-div{
            margin-top: 5px;
            width: 60%;
        }

    }
    .we-are-all-right-two {
        grid-column: 2/3;

    }


}

@media (max-width: 1500px){
    .we-are-all-main .we-are-all-left h3{
        font-size: 40px;
        line-height: 50px;
    }
    .we-are-all-main .we-are-all-left p{
        font-size: 12px;
    }
    .we-are-all-main .we-are-all-right-one .cytat{
        width: 75%;

        .cudzyslow-text{
            font-size: 12px;
            line-height: 13px;
        }
    }
    .we-are-all-main .we-are-all-right-one .the-boss {
        width: 75%;
    }

    .we-are-all-main .we-are-all-right-one .button-div{
        width: 75%;
    }

}

@media (max-width: 1300px){
    .we-are-all-main .we-are-all-left h3{
        font-size: 30px;
        line-height: 40px;
    }

    .we-are-all-main .we-are-all-left p{
        font-size: 12px;
    }

    .we-are-all-main .we-are-all-right-one .cytat .cudzyslow-text {
    font-size: 12px;
    line-height: 10px;;
    }

    .we-are-all-main .we-are-all-right-one .the-boss .boss-foto img{
        max-width: 70px;
    }

}

@media (max-width: 1024px){
    .we-are-all-main{
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .we-are-all-main .we-are-all-left{
        grid-column: 1/3;
    }

    .we-are-all-main .we-are-all-right-one .cytat, 
    .we-are-all-main .we-are-all-right-one .the-boss,
    .we-are-all-main .we-are-all-right-one .button-div{
        width: 100%;
    }


}

@media (max-width: 768px){
    .we-are-all-main{
        background-image: url('../images/webp/mobileBg1_edited.webp');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }

    .we-are-all-main .we-are-all-left h3{
        font-size: 20px;
        line-height: 27px;
    }
    .we-are-all-main .we-are-all-left p br{
        display: none;
    }

    .we-are-all-main .we-are-all-right-one{
        grid-column: 1/3;
        margin-top: 50px;
    }
}