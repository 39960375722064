.header-main {
    display: grid;
    grid-template-columns: 60% 40%;

    .col-one {
        padding-top: 64px;
        grid-column: 1/2;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .col-two {
        padding-top: 64px;
        grid-column: 2/3;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .language-switcher {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
      }
      
      .dot-space{
        width: 35px;
        border: 1px solid #474EFF;
        border-radius: 20px;
        margin-left: 5px;
        margin-right: 5px;
       
      }

      .dot {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #474EFF;
        margin-right: 10px;
        cursor: pointer;
        transition: transform 0.3s ease-in-out;
      }
      
      .dot.en {
        transform: translateX(0);
      }
      
      .dot.pl {
        transform: translateX(20px);
      }

      .blue-color{
        color: #474EFF;
        cursor: pointer;
      }

      .white-color{
        color: #fff;
        cursor: pointer;
      }

      span{
        font-size: 16px;
      }
   

}