.portfolio-section {
    padding-top: 163px;

    .first-title-div {
        margin-bottom: 113px;
        display: grid;
        grid-template-columns: 40% 1fr 1fr;


        .col-one {
            grid-column: 1/2;

            .first-title {
                display: inline;
                font-size: 50px;
                line-height: 72px;
                font-weight: 400;
            }

        }

        .col-two {
            grid-column: 2/3;
            display: flex;
            align-items: flex-end;

            p {
                font-family: 'satoshi', serif;
                font-weight: 400;
                font-size: 18px;
                line-height: 159%;
            }
        }


        .col-three {
            grid-column: 3/4;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            .deco-icon {
                padding-left: 30px;
            }
        }
    }

    .client-name {
        font-size: 50px;
        line-height: 72px;
        font-weight: 400;
        margin-bottom: 13px;
    }

    .client-description1 {
        display: grid;
        grid-template-columns: 40% 60%;
        margin-bottom: 250px;
        gap: 50px;

        .client-text {
            padding: 13px 20px 0px 0px;

            .desc-big {
                font-family: 'satoshi', serif;
                font-size: 28px;
                font-style: normal;
                font-weight: 500;
                line-height: 173.105%;
            }

            .desc-small {
                margin-top: 28px;
                font-family: 'satoshi', serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 173.105%;
            }

            .purpple-text {
                font-size: 13px;
                font-weight: 300;
                line-height: 20px;
                margin-top: 40px;
                color: #474EFF;

            }

            .client-web {
                color: #ffffff;
                font-size: 30px;
                font-weight: 300;
                line-height: 38px;
            }
        }

        .client-foto {
            display: flex;
            justify-content: flex-end;

            img {
                max-height: 447px;               
            }
        }

    }

    .client-description2 {
        display: grid;
        grid-template-columns: 60% 40%;
        gap: 50px;
        margin-bottom: 250px;

        .client-text {
            grid-column: 2/3;
            float: right;
            padding: 13px 20px 0px 0px;

            .desc-big {
                font-family: 'satoshi', serif;
                font-size: 28px;
                font-style: normal;
                font-weight: 500;
                line-height: 173.105%;
            }

            .desc-small {
                margin-top: 28px;
                font-family: 'satoshi', serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 173.105%;
            }
            .purpple-text {
                font-size: 13px;
                font-weight: 300;
                line-height: 20px;
                margin-top: 40px;
                color: #474EFF;

            }

            .client-web {
                color: #ffffff;
                font-size: 30px;
                font-weight: 300;
                line-height: 38px;
            }
        }

        .client-foto {
            grid-column: 1/2;

            img {
                max-height: 410px;
            }
        }

    }

    .client-description3 {
        display: grid;
        grid-template-columns: 35% 65%;
        margin-bottom: 250px;
        gap: 50px;

        .client-text {
            padding: 13px 20px 0px 0px;

            .desc-big {
                font-family: 'satoshi', serif;
                font-size: 28px;
                font-style: normal;
                font-weight: 500;
                line-height: 173.105%;
            }

            .desc-small {
                margin-top: 28px;
                font-family: 'satoshi', serif;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 173.105%;
            }

            .purpple-text {
                font-size: 13px;
                font-weight: 300;
                line-height: 20px;
                margin-top: 40px;
                color: #474EFF;

            }

            .client-web {
                color: #ffffff;
                font-size: 30px;
                font-weight: 300;
                line-height: 38px;
            }
        }

        .client-foto {
            display: flex;
            justify-content: flex-end;

            img {
                max-height: 447px;               
            }
        }

    }


    .link-with-arrow {
        grid-column: 2/3;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 40px;
    }
}

@media (max-width: 1700px) {

    .client-description1,
    .client-description2 {
        .client-foto {
            img {
                max-height: 380px !important;
            }
        }
    }

    .we-are-all-main .we-are-all-left h3 {
        br {
            display: none;
        }
    }

    .we-are-all-main .we-are-all-right-one .cytat .cudzyslow-text {
        br {
            display: none;
        }
    }

}

@media (max-width: 1500px) {
    .portfolio-section .first-title-div .col-one .first-title {
        font-size: 40px;
        line-height: 55px;
    }

    .client-description1,
    .client-description2,
    .client-description3 {
        grid-template-columns: 50% 50% !important;

        .client-text {
          .desc-big{
            font-size: 24px !important;
          }
        }

        .client-foto {
            display: flex;
            align-items: center;

            img {
                max-height: 240px !important;
            }
        }

    }

}

@media (max-width: 1300px) {
    .portfolio-section .first-title-div .col-one .first-title {
        font-size: 30px;
        line-height: 40px;
    }

    .portfolio-section .first-title-div .col-two p {
        font-size: 12px;
    }

    .client-description1 .client-foto img,
    .client-description2 .client-foto img,
    .client-description3 .client-foto img {
        max-height: 220px !important;
    }

    .portfolio-section .client-name {
        font-size: 40px;
    }

   


}

@media (max-width: 1024px) {
    .portfolio-section .first-title-div .col-one {
        grid-column: 1/3;
    }

    .portfolio-section .first-title-div .col-two {
        grid-column: 1/3;
        margin-top: 25px;
    }

    .client-description1,
    .client-description2,
    .client-description3 {
        grid-template-columns: 1fr !important;
    }

    .client-description2 .client-text {
        grid-column: 1/2 !important;
        grid-row: 1/2;
    }

    .client-description2 .client-foto {
        grid-column: 1/2 !important;
        grid-row: 2/3;
    }

    .client-description3 .client-foto {
        grid-column: 1/2 !important;
        grid-row: 3/4;
    }

    .portfolio-section .client-description1 .client-foto,
    .portfolio-section .client-description2 .client-foto,
    .portfolio-section .client-description3 .client-foto {
        justify-content: center;
        margin-top: 10px;
    }

    .portfolio-section .client-description1,
    .portfolio-section .client-description2,
    .portfolio-section .client-description3 {
        margin-bottom: 50px;
    }

    .portfolio-section .first-title-div .col-one {
        grid-column: 1/4;
    }
}

@media (max-width: 768px) {
    .portfolio-section {

        padding-top: 100px;
    }

    .portfolio-section .first-title-div {
        margin-bottom: 50px;
    }

    .portfolio-section .first-title-div .col-one {
        grid-column: 1/4;

        .first-title {
            font-size: 25px;
            line-height: 30px;

        }
    }

    .portfolio-section .first-title-div .col-two {
        grid-column: 1/4;
    }

    .portfolio-section .first-title-div .col-three .deco-icon {
        width: 56px;
    }

    .portfolio-section .client-description1 .client-text {
        max-width: 100vw;
    }

    .portfolio-section .client-name {
        font-size: 30px;
    }

    .portfolio-section .client-description1 .client-foto img,
    .portfolio-section .client-description2 .client-foto img,
    .portfolio-section .client-description3 .client-foto img {
        max-width: 90vw;
    }

}