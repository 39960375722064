.main-container{
    width: 100%;
    background-image: url('../images/webp/diamond_edited.webp');
    background-size: contain;
    background-position: right; 
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;

    .content-container{
        max-width: 1636px;
        min-width: 85%;
        padding: 157px 0px 105px 0px;

        .small-text{
            font-family: 'satoshi';
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }

        .first-title{
            margin-top: 33px;
            font-size: 50px;
            font-weight: 400;
            line-height: 72px;
        }

        .counters{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 50px;
            margin-top: 149px;

            .counter1{
                grid-column: 1/2;

            }
            .counter2{
                grid-column: 2/3;
            }
            .counter3{
                grid-column: 3/4;
            }
            .counter4{
                grid-column: 4/5;
            }

            .thin-line{
                size: 0.6px;
                color: #ffffff;
                margin-bottom: 30px;
            }

            .counter-description{
                font-family: 'satoshi', serif;
                margin-top: 24px;
                font-size: 13px;
                line-height: 17px;
                font-weight: 400;
                color: #DCDBDB;
            }
        }


    }
}

@media (max-width: 1700px){
    .content-container{
        max-width: 1500px !important;
    }
}

@media (max-width: 1500px){

    .content-container{
        max-width: 1250px !important;
    }


    .main-container .content-container .first-title{
        font-size: 36px;
        line-height: 55px;
    }
    .main-container .content-container .counters{
        max-width: 95%;
    }
}

@media (max-width: 1300px){
    .content-container{
        max-width: 1024px !important;
    }
    .main-container .content-container .small-text{
        font-size: 14px;
    }
    .main-container .content-container .first-title{
        font-size: 30px;
        line-height: 40px;
    }

}

@media (max-width: 1024px){
    .content-container{
        max-width: 768px !important;
        padding: 50px 0px 50px 0px !important;
    }

    .main-container {
        background-size: cover;
    }

    .main-container .content-container .counters .counter-description{
        font-size: 12px;
    }
}

@media (max-width: 768px){
    .content-container{
        padding: 50px 0px 50px 20px !important;
    }
    .main-container .content-container .first-title{
        font-size: 20px;
        line-height: 27px;
    }
    .main-container .content-container .counters{
        grid-template-columns: 47% 47%;
        gap: 6%;
    }

    .main-container .content-container .counters .counter3{
        grid-column: 1/2;
    }

    .main-container .content-container .counters .counter4{
        grid-column: 2/3;
    }

    .main-container .content-container .counters .counter-description{
        line-height: 13px;
    }

    .main-container .content-container .counters .counter-description br{
        display: none;
    }

}