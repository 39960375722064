.container-grid {
    display: grid;
    grid-template-columns: 60% 40%;
    margin-top: 338px;
    margin-bottom: 331px;

    .left {
        grid-column: 1/2;

        .we-only-work {
            font-weight: 400;
            font-size: 50px;
            line-height: 143%;
            .text-row-2{
                padding-left: 60px;
            }
            .text-row-3{
                padding-left: 120px;
            }
        }
        .we-are-global-agency{
            font-family: 'satoshi', serif;
            font-size: 14px;
            padding-left: 180px;
            margin-top: 47px;
            margin-bottom: 47px;
            line-height: 22px;
            font-weight: 400;
        }
        .contact-us-button{
            padding-left: 232px;
        }
    }

    .right {
        grid-column: 2/3;

        .logos-grid{
            margin-top: 20px;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            justify-content: flex-end;
        }

        .logos-grid > *{
            flex-basis: 32%;
        }
        .more-clients{
            display: flex;
            justify-content: flex-end;
            padding-top: 30px
        }
    }
}

@media (max-width: 1500px){
    .container-grid .left .we-only-work{
        font-size: 36px;
    }
}

@media (max-width: 1300px){
    .container-grid{
        grid-template-columns: 50% 50%;
    }
    .container-grid .left .we-only-work{
        font-size: 30px;
    }
    .container-grid .left .we-are-global-agency{
        font-size: 14px;;
    }
}

@media (max-width: 1024px){
    .container-grid{
        margin-top: 120px;
        margin-bottom: 120px;
    }

    .container-grid .left{
        grid-column: 1/3;
    }
    .container-grid .right{
        grid-column: 1/3;
    }

    .container-grid .left .contact-us-button{
        float: right;
        margin-bottom: 100px;
    }
}

@media (max-width: 768px){
    .container-grid .left .we-only-work{
        font-size: 20px;
    }
    .container-grid .left .we-are-global-agency{
        padding-left: 0px;
    }
    .container-grid .right .logos-grid > * {
        flex-basis: auto;
    }

    
   
}

@media (max-width: 385px){
    .container-grid .left .we-only-work{
        font-size: 18px;
    }

} 