.newsletter-input{
    width: 90%;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #FFFFFF;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-top: 10px;
    margin-bottom: 33px;
    color: #ffffff;

}

.newsletter-input::placeholder{
    color: #ffffff;
    font-size: 14px;
    font-family: 'Unbounded';
}