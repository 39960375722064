.round-text{
    position: relative;
    max-width: fit-content;
    

    .text-svg{
        animation: spinAround 5000ms infinite linear;
        border-radius: 50%;
        padding: 7px;
        transition: all 700ms;
    }

    .arrow-svg{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all 700ms;
    }

}

.round-text:hover .text-svg {
    //animation-play-state: paused;
    background-color: #ffffff;
    animation: spinAround 15000ms infinite linear;
}

.round-text:hover .arrow-svg{
    filter: invert(1);
};



.arrow-svg:hover{
    cursor: pointer;
}



@keyframes spinAround {
    0%{
        transform: rotateZ(0deg);
    }
    100%{
        transform: rotateZ(360deg);
    }
    
}

@media (max-width: 1024px){
    .round-text img{
        max-width: 150px;
    }
}

@media (max-width: 768px){
    .round-text .text-svg{
        max-width: 90px;
    }

    .round-text .arrow-svg{
        max-width: 22px;
    }
}