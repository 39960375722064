.bottom-bar-container{
    position: relative;
    display: grid;
    grid-template-columns: 25% 1fr 25%;
    margin-bottom: 67px;
    
    .col-one{
        grid-column: 1/2;
        padding-right: 5%;
        display: flex;
        justify-content: space-between;
    }

    .col-two{
        grid-column: 2/3;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        .gray-link{
            color: #777777;
            font-weight: 300;
            font-size: 16px;
            line-height: 20px;
        }
    }

    .col-three{
        grid-column: 3/4;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        p{
            font-size: 16px;
            font-weight: 300;

        }
    }

    .privacy-policy-container{
        position: absolute;
        bottom: 50px;
        background-color: #070708;
        height: 0vh;
        opacity: 0;
        overflow: hidden;
        z-index: -20;
        border-top: 5px double #ffffff;
        border-left: 5px double #ffffff;
        border-right: 5px double #ffffff;
        transition: height 700ms, opacity 700ms;
        overflow: auto;

        .privacy-policy-div{
            padding: 20px 20px 20px 20px;
            .exit-policy{
                float: right;
                font-size: 24px;
                margin-top: -20px
            }
            .exit-policy:hover{
                cursor: pointer;
            }
            p{
                margin-top: 50px;
                font-size: 16px;
            }
            ul{
                margin-left: 50px;
            }
        }
        
    }

    .cookie-policy-container{
        position: absolute;
        bottom: 50px;
        background-color: #070708;
        height: 0vh;
        opacity: 0;
        overflow: hidden;
        z-index: -20;
        border-top: 5px double #ffffff;
        border-left: 5px double #ffffff;
        border-right: 5px double #ffffff;
        transition: height 700ms, opacity 700ms;

        .cookie-policy-div{
            padding: 20px 20px 20px 20px;
            .exit-policy{
                float: right;
                font-size: 24px;
                margin-top: -20px
            }
            .exit-policy:hover{
                cursor: pointer;
            }
            p{
                margin-top: 50px
            }
            a{
                color: #ffffff;
            }
        }

    }
}

.policy-active{
    height: 85vh !important;
    opacity: 1 !important;
    z-index: 50 !important;
}

#privacy-policy:hover,
#cookie-policy:hover{
    cursor: pointer;
}

@media (max-width: 1700px){
    .bottom-bar-container .col-three p{
        font-size: 14px;
    }
}
@media (max-width: 1500px){
    .bottom-bar-container .col-three p{
        font-size: 12px;
    }

}

@media (max-width: 1300px){
    .bottom-bar-container .col-two .gray-link{
        font-size: 9px;

    }

    .bottom-bar-container .col-three p {
        font-size: 9px;
    }

    .bottom-bar-container .col-one img{
        max-width: 25px;
    } 
}

@media (max-width: 1024px){
    .bottom-bar-container .col-three p{
        font-size: 8px;
    }
}

@media (max-width: 768px){
    .bottom-bar-container .col-one,
    .bottom-bar-container .col-two,
    .bottom-bar-container .col-three{
        grid-column: 1/5;
        margin-top: 10px;
    }

    .bottom-bar-container .col-three{
        justify-content: center;
    }

    .bottom-bar-container {
        margin-bottom: 20px;
    }

    .privacy-policy-container,
    .cookie-policy-container{
        h2{
            font-size: 16px
        }
        p{
            font-size: 12px !important;
        }
    }


}