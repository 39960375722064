.footer-main-container {
  margin-top: 80px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.footer-main-container .col-one {
  grid-column: 1/2;
}
.footer-main-container .col-one p {
  font-family: "satoshi";
  margin-top: 30px;
  font-weight: 400;
  font-size: 18px;
  line-height: 159%;
}
.footer-main-container .col-two {
  grid-column: 2/3;
  display: grid;
  grid-template-columns: 30% 1fr;
  gap: 8%;
}
.footer-main-container .col-two .col-title {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  grid-column: 1/2;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
}
.footer-main-container .col-two .col-data {
  font-family: "satoshi";
  font-weight: 400;
  grid-column: 2/3;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}
.footer-main-container .col-two .newsletter-input {
  width: 90%;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #FFFFFF;
  padding-bottom: 10px;
  padding-top: 10px;
  margin-top: 10px;
  margin-bottom: 33px;
}
.footer-main-container .col-two .newsletter-input::-moz-placeholder {
  color: #ffffff;
  font-size: 14px;
  font-family: "Unbounded";
}
.footer-main-container .col-two .newsletter-input::placeholder {
  color: #ffffff;
  font-size: 14px;
  font-family: "Unbounded";
}
.footer-main-container .col-three {
  grid-column: 3/4;
  display: grid;
  grid-template-columns: 30% 1fr;
  gap: 8%;
}
.footer-main-container .col-three .col-title {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  grid-column: 1/2;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
}
.footer-main-container .col-three .col-data {
  font-family: "satoshi";
  font-weight: 400;
  grid-column: 2/3;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
}
.footer-main-container .col-three .col-data .contact-link {
  margin-top: 20px;
  display: block;
  color: #ffffff;
}
.footer-main-container .deco-icons-all {
  grid-column: 1/3;
  display: inline-flex;
  margin-top: 89px;
  margin-bottom: 89px;
  gap: 16px;
}

@media (max-width: 1500px) {
  .footer-main-container .col-one p {
    font-size: 12px;
  }
  .footer-main-container .col-two .col-title,
  .footer-main-container .col-three .col-title {
    font-size: 18px;
  }
  .footer-main-container .col-two .col-data,
  .footer-main-container .col-three .col-data {
    font-size: 12px;
    line-height: 18px;
  }
}
@media (max-width: 1300px) {
  .col-one img {
    max-width: 200px;
  }
  .footer-main-container .col-one p {
    font-size: 12px;
  }
  .footer-main-container .col-two .col-title,
  .footer-main-container .col-three .col-title {
    font-size: 14px;
  }
  .footer-main-container .col-two .col-data,
  .footer-main-container .col-three .col-data {
    font-size: 12px;
    line-height: 16px;
  }
  .col-two .col-data p br {
    display: none;
  }
}
@media (max-width: 1024px) {
  .footer-main-container {
    margin-top: 0px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .footer-main-container .col-one {
    grid-column: 1/5;
  }
  .footer-main-container .col-two {
    grid-column: 1/3;
    margin-top: 50px;
  }
  .footer-main-container .col-three {
    grid-column: 3/5;
    margin-top: 50px;
  }
  .footer-main-container .deco-icons-all {
    grid-column: 1/5;
    margin: 40px 0px 30px 0px;
  }
  .footer-main-container .deco-icons-all .icon-frame-s:first-child {
    width: 145px !important;
  }
  .footer-main-container .deco-icons-all .icon-frame-s:nth-child(2) {
    width: 155px !important;
  }
  .footer-main-container .deco-icons-all .icon-frame-s:nth-child(3) {
    width: 125px !important;
  }
  .footer-main-container .deco-icons-all .icon-frame-s-pl:first-child {
    width: 145px !important;
  }
  .footer-main-container .deco-icons-all .icon-frame-s-pl:nth-child(2) {
    width: 185px !important;
  }
  .footer-main-container .deco-icons-all .icon-frame-s-pl:nth-child(3) {
    width: 125px !important;
  }
}
@media (max-width: 768px) {
  .footer-main-container .col-two,
  .footer-main-container .col-three {
    grid-column: 1/5;
  }
  .footer-main-container .deco-icons-all {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
}/*# sourceMappingURL=FooterMain.css.map */