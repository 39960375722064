.cookie-settings {
  position: fixed;
  left: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  padding: 1%;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}
.cookie-settings h2 {
  font-size: 24px;
  line-height: 30px;
}
.cookie-settings p {
  font-size: 14px;
  line-height: 20px;
}
.cookie-settings button {
  padding: 5px;
  margin-right: 10px;
  margin-top: 10px;
  background-color: #474EFF;
  color: #ffffff;
}
.cookie-settings button:hover {
  cursor: pointer;
}/*# sourceMappingURL=CookieSettings.css.map */