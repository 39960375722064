.contact-form-main-container {
    height: 100vh;
    width: 100%;
    display: grid;
    overflow: auto;
    grid-template-columns: 60% 40%;

    .contact-left {
        grid-column: 1/2;
        background-color: #070708B2;
    }

    .contact-right {
        grid-column: 2/3;
        background-color: #ffffff;
        padding: 50px 65px 50px 65px;

        .contact-title,
        .contact-x,
        .contact-description,
        .contact-label,
        .contact-priv-policy {
            color: #000000;
        }


        .contact-form-two {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 45px;

            .contact-tittle-div {
                grid-column: 1/3;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .contact-title {
                    font-size: 38px;
                    font-weight: 400;
                }

                .contact-x {
                    width: 35px;
                    height: 35px;
                    background-color: #D9D9D9;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .contact-x:hover {
                    cursor: pointer;
                }



            }

            .contact-description {
                grid-column: 1/3;
                font-size: 16px;
                font-weight: 300;
                margin-top: -40px;
            }

            .contact-first-name {
                margin-top: -20px;
                grid-column: 1/2;
            }

            .contact-last-name {
                margin-top: -20px;
                grid-column: 2/3;
            }

            .contact-email {
                margin-top: -20px;
                grid-column: 1/3;
            }

            .contact-phone-nr {
                margin-top: -20px;
                grid-column: 1/2;
            }

            .contact-what-source {
                margin-top: -20px;
                grid-column: 2/3;
            }

            .contact-your-message {
                margin-top: -20px;
                grid-column: 1/3;
            }

            .contact-privacy-checkbox {
                margin-top: -20px;
                grid-column: 1/3;

                label {
                    font-size: 16px;
                    font-weight: 300;
                    margin-left: 12px;
                }
            }

            .button-send-two {
                grid-column: 2/3;
                display: flex;
                justify-content: flex-end;
            }

            .contact-label {
                font-size: 16px;
                font-weight: 300;

            }

            .contact-input {
                font-family: 'Unbounded';
                margin-top: 15px;
                border: none;
                border-bottom: 1px solid #777777;
                padding: 12px 22px 12px 22px;
                width: 100%;
                background-color: #F5F5F5;

            }

            .contact-input::placeholder {
                font-family: 'Unbounded';
                font-style: normal;
                font-weight: 300;
                font-size: 16px;
                line-height: 22px;
            }

        }
    }

}

@media (max-width: 1024px) {
    .contact-form-main-container {
        grid-template-columns: 40% 60%;

        .contact-right {
            .contact-title {
                font-size: 28px !important;
            }

            .contact-description,
            .contact-label,
            .contact-privacy-checkbox label,
            .contact-input,
            .contact-input::placeholder {
                font-size: 14px !important;
            }
        }
    }


}

@media (max-width: 768px) {
    .contact-form-main-container {
        grid-template-columns: 1fr;

        .contact-left {
            display: none;
        }

        .contact-right {
            grid-column: 1/2;
            padding: 20px 20px 20px 20px;

            .contact-form-two {
                gap: 20px;

                .contact-title {
                    font-size: 22px !important;
                }

                .contact-description {
                    margin-top: -10px;
                    font-size: 12px !important;
                }

                .contact-label,
                .contact-privacy-checkbox label,
                .contact-input,
                .contact-input::placeholder {
                    font-size: 10px !important;
                }

                .contact-first-name,
                .contact-last-name,
                .contact-email,
                .contact-phone-nr,
                .contact-what-source,
                .contact-your-message {
                    margin-top: 0px;
                }

                .contact-phone-nr,
                .contact-what-source{
                    .contact-label{
                        height: 24px;
                    }                
                }
            }

        }
    }
}