.bottom-bar-container {
  position: relative;
  display: grid;
  grid-template-columns: 25% 1fr 25%;
  margin-bottom: 67px;
}
.bottom-bar-container .col-one {
  grid-column: 1/2;
  padding-right: 5%;
  display: flex;
  justify-content: space-between;
}
.bottom-bar-container .col-two {
  grid-column: 2/3;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.bottom-bar-container .col-two .gray-link {
  color: #777777;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
}
.bottom-bar-container .col-three {
  grid-column: 3/4;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.bottom-bar-container .col-three p {
  font-size: 16px;
  font-weight: 300;
}
.bottom-bar-container .privacy-policy-container {
  position: absolute;
  bottom: 50px;
  background-color: #070708;
  height: 0vh;
  opacity: 0;
  overflow: hidden;
  z-index: -20;
  border-top: 5px double #ffffff;
  border-left: 5px double #ffffff;
  border-right: 5px double #ffffff;
  transition: height 700ms, opacity 700ms;
  overflow: auto;
}
.bottom-bar-container .privacy-policy-container .privacy-policy-div {
  padding: 20px 20px 20px 20px;
}
.bottom-bar-container .privacy-policy-container .privacy-policy-div .exit-policy {
  float: right;
  font-size: 24px;
  margin-top: -20px;
}
.bottom-bar-container .privacy-policy-container .privacy-policy-div .exit-policy:hover {
  cursor: pointer;
}
.bottom-bar-container .privacy-policy-container .privacy-policy-div p {
  margin-top: 50px;
  font-size: 16px;
}
.bottom-bar-container .privacy-policy-container .privacy-policy-div ul {
  margin-left: 50px;
}
.bottom-bar-container .cookie-policy-container {
  position: absolute;
  bottom: 50px;
  background-color: #070708;
  height: 0vh;
  opacity: 0;
  overflow: hidden;
  z-index: -20;
  border-top: 5px double #ffffff;
  border-left: 5px double #ffffff;
  border-right: 5px double #ffffff;
  transition: height 700ms, opacity 700ms;
}
.bottom-bar-container .cookie-policy-container .cookie-policy-div {
  padding: 20px 20px 20px 20px;
}
.bottom-bar-container .cookie-policy-container .cookie-policy-div .exit-policy {
  float: right;
  font-size: 24px;
  margin-top: -20px;
}
.bottom-bar-container .cookie-policy-container .cookie-policy-div .exit-policy:hover {
  cursor: pointer;
}
.bottom-bar-container .cookie-policy-container .cookie-policy-div p {
  margin-top: 50px;
}
.bottom-bar-container .cookie-policy-container .cookie-policy-div a {
  color: #ffffff;
}

.policy-active {
  height: 85vh !important;
  opacity: 1 !important;
  z-index: 50 !important;
}

#privacy-policy:hover,
#cookie-policy:hover {
  cursor: pointer;
}

@media (max-width: 1700px) {
  .bottom-bar-container .col-three p {
    font-size: 14px;
  }
}
@media (max-width: 1500px) {
  .bottom-bar-container .col-three p {
    font-size: 12px;
  }
}
@media (max-width: 1300px) {
  .bottom-bar-container .col-two .gray-link {
    font-size: 9px;
  }
  .bottom-bar-container .col-three p {
    font-size: 9px;
  }
  .bottom-bar-container .col-one img {
    max-width: 25px;
  }
}
@media (max-width: 1024px) {
  .bottom-bar-container .col-three p {
    font-size: 8px;
  }
}
@media (max-width: 768px) {
  .bottom-bar-container .col-one,
  .bottom-bar-container .col-two,
  .bottom-bar-container .col-three {
    grid-column: 1/5;
    margin-top: 10px;
  }
  .bottom-bar-container .col-three {
    justify-content: center;
  }
  .bottom-bar-container {
    margin-bottom: 20px;
  }
  .privacy-policy-container h2,
  .cookie-policy-container h2 {
    font-size: 16px;
  }
  .privacy-policy-container p,
  .cookie-policy-container p {
    font-size: 12px !important;
  }
}/*# sourceMappingURL=FooterBottomBar.css.map */