.blue-button2 {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #474EFF;
  width: 152px;
  height: 48px;
  border-radius: 34px;
  transition: all 500ms;
  border: none !important;
  box-shadow: 0px 0px 36px 3px #474EFF;
}
.blue-button2 .button-text2 {
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  font-family: "Unbounded";
  text-align: center;
  transition: color 500ms;
}

.blue-button2:hover {
  cursor: pointer;
  transition: all 500ms;
  box-shadow: 0px 0px 50px 8px #474EFF;
}
.blue-button2:hover .button-text2 {
  transition: color 500ms;
  color: #000000;
}/*# sourceMappingURL=BlueButton2.css.map */