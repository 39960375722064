.icon-frame-s,
.icon-frame-s-pl {
  border: 1px solid #474EFF;
  border-radius: 100px;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  -moz-column-gap: 5px;
       column-gap: 5px;
}
.icon-frame-s .icon-svg-s,
.icon-frame-s-pl .icon-svg-s {
  width: 24px;
}
.icon-frame-s .icon-title-s,
.icon-frame-s-pl .icon-title-s {
  font-size: 22px;
  font-weight: 300;
  line-height: 34px;
  color: #474EFF;
}

@media (max-width: 1024px) {
  .icon-frame-s .icon-svg-s,
  .icon-frame-s-pl .icon-svg-s {
    width: 15px;
  }
  .icon-frame-s .icon-title-s,
  .icon-frame-s-pl .icon-title-s {
    font-size: 15px;
    line-height: 25px;
  }
}/*# sourceMappingURL=IconFrameS.css.map */