.header-main {
  display: grid;
  grid-template-columns: 60% 40%;
}
.header-main .col-one {
  padding-top: 64px;
  grid-column: 1/2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.header-main .col-two {
  padding-top: 64px;
  grid-column: 2/3;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-main .language-switcher {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}
.header-main .dot-space {
  width: 35px;
  border: 1px solid #474EFF;
  border-radius: 20px;
  margin-left: 5px;
  margin-right: 5px;
}
.header-main .dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #474EFF;
  margin-right: 10px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}
.header-main .dot.en {
  transform: translateX(0);
}
.header-main .dot.pl {
  transform: translateX(20px);
}
.header-main .blue-color {
  color: #474EFF;
  cursor: pointer;
}
.header-main .white-color {
  color: #fff;
  cursor: pointer;
}
.header-main span {
  font-size: 16px;
}/*# sourceMappingURL=header.css.map */