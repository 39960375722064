.hero-section {
  display: grid;
  grid-template-columns: 34% 66%;
  max-width: 100vw;
}
.hero-section .left-column {
  padding-top: 245px;
  overflow: visible;
  z-index: 20;
}
.hero-section .left-column .slogan-div h1 {
  font-size: 80px;
}
.hero-section .left-column .slogan-div .first-row {
  width: 950px;
  display: flex;
}
.hero-section .left-column .slogan-div .icons-div {
  position: relative;
  width: 150px;
  margin-left: 5px;
}
.hero-section .left-column .slogan-div .icons-div .o-icon {
  position: absolute;
  bottom: 20px;
  animation: o-icon-anim 9s infinite ease-in-out;
}
.hero-section .left-column .slogan-div .icons-div .inf-icon {
  position: absolute;
  bottom: 20px;
  opacity: 0;
  animation: inf-icon-anim 9s infinite ease-in-out;
}
@keyframes o-icon-anim {
  0% {
    opacity: 1;
    filter: blur(0px);
  }
  30% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    filter: blur(20px);
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    filter: blur(0px);
  }
}
@keyframes inf-icon-anim {
  0% {
    opacity: 0;
    filter: blur(20px);
  }
  50% {
    opacity: 1;
    filter: blur(0px);
  }
  100% {
    opacity: 0;
    filter: blur(20px);
  }
}
.hero-section .left-column .hero-description {
  font-family: "satoshi", serif;
  font-weight: 400;
  width: 100%;
  display: grid;
  grid-template-columns: 505px 1fr;
}
.hero-section .left-column .hero-description .description-left {
  grid-column: 1/2;
}
.hero-section .left-column .hero-description .description-left .description-text {
  margin-top: 50px;
  font-size: 14px;
  line-height: 22px;
}
.hero-section .left-column .hero-description .description-left .round-text-div {
  margin-top: 90px;
}
.hero-section .left-column .hero-description .description-right {
  grid-column: 2/3;
  overflow: visible;
}
.hero-section .left-column .hero-description .description-right .deco-ico-1 {
  margin-top: -22px;
}
.hero-section .left-column .hero-description .description-right .deco-ico-2,
.hero-section .left-column .hero-description .description-right .deco-ico-3 {
  margin-top: 10px;
}
.hero-section .right-column {
  position: relative;
  z-index: 10;
}
.hero-section .right-column .video-container {
  min-width: 81%;
  max-width: 811px;
  height: 82vh;
  overflow: hidden;
  position: relative;
  margin-right: 50px;
  margin-left: auto;
}
.hero-section .right-column .video-container video {
  -o-object-fit: cover;
     object-fit: cover;
  height: 82vh;
}
.hero-section .right-column .video-container::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 1;
  opacity: 0.5;
}
.hero-section .right-column .we-invite-you {
  position: absolute;
  right: 0;
  bottom: 0;
}

@media (max-width: 1024px) {
  .slogan-div h1 {
    font-size: 55px !important;
  }
  .slogan-div .first-row {
    width: 700px !important;
  }
  .slogan-div .icons-div {
    width: 120px !important;
  }
  .slogan-div .o-icon,
  .slogan-div .inf-icon {
    bottom: 10px !important;
    height: 50px !important;
  }
}
@media (max-width: 768px) {
  .slogan-div h1 {
    font-size: 26px !important;
  }
  .slogan-div .first-row {
    width: 350px !important;
  }
  .slogan-div .icons-div {
    margin-left: 1px !important;
    width: 52px !important;
  }
  .slogan-div .o-icon,
  .slogan-div .inf-icon {
    bottom: 5px !important;
    height: 22px !important;
  }
  .hero-section .left-column .hero-description {
    grid-template-columns: 1fr;
    width: 90vw;
  }
  .hero-section .left-column .hero-description .description-right .deco-ico-1 {
    margin-top: 50px;
  }
  .hero-section .left-column .hero-description .description-right .deco-ico-1 .icon-frame {
    width: -moz-fit-content !important;
    width: fit-content !important;
  }
  .hero-section .left-column .hero-description .description-right .deco-ico-2 .icon-frame {
    width: -moz-fit-content !important;
    width: fit-content !important;
  }
  .hero-section .left-column .hero-description .description-right .deco-ico-3 .icon-frame {
    width: -moz-fit-content !important;
    width: fit-content !important;
  }
  .hero-section .left-column .hero-description .description-left .description-text br {
    display: none;
  }
  .hero-section .right-column .video-container {
    min-width: 80%;
    max-width: 800px;
    height: 70vh;
    overflow: hidden;
    position: relative;
    margin-right: -10%;
    margin-left: auto;
  }
  .hero-section .right-column .we-invite-you {
    bottom: -10%;
  }
}/*# sourceMappingURL=HeroSection.css.map */