.infinity-div{
    min-height: 66px;
    display: flex;
    align-items: center;

    .infinity{
        animation: infinityAnim 5000ms infinite ease-in-out;
    }
}

@keyframes infinityAnim {
    0%{
        transform: rotateY(0deg);
    }
    10%{
        transform: rotateY(0deg);
    }
    90%{
        transform: rotateY(360deg);
    }
    100%{
        transform: rotateY(360deg);
    }
    
}

@media (max-width: 1300px){
    .infinity-div img{
        max-height: 32px;
    }
}

@media (max-width: 768px){
    .infinity-div img{
        max-height: 28px;
    }

    .infinity-div{
        min-height: 30px;
    }
}