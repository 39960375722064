.partners-container {
  height: 222px;
  border-top: 1px solid rgba(255, 255, 255, 0.1607843137);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1607843137);
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 80px;
}

@media (max-width: 1024px) {
  .partners-container {
    height: 150px;
  }
  .partners-container img {
    max-width: 10%;
  }
}/*# sourceMappingURL=Partners.css.map */