.logo-brick{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 96px;
    border: 1px solid rgba(255, 255, 255, 0.14);

}

@media (max-width: 768px){
    .logo-brick{
        width: 30%;
        overflow: hidden;

        img{
            max-width: 80%;
        }
    }
}