.white-menu-section {
  background-color: #ffffff;
  padding: 149px 142px 149px 142px;
}
.white-menu-section .white-menu-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 55% 45%;
}
.white-menu-section .white-menu-grid .white-menu-left {
  grid-column: 1/2;
  position: relative;
}
.white-menu-section .white-menu-grid .white-menu-left .description {
  width: 72%;
  font-family: "satoshi", serif;
  font-size: 18px;
  font-weight: 400;
}
.white-menu-section .white-menu-grid .white-menu-left .contact-form-div {
  position: relative;
  bottom: 0;
  width: 100%;
}
.white-menu-section .white-menu-grid .white-menu-left .contact-form-div .contact-description {
  width: 72%;
  font-size: 28px;
  line-height: 35px;
  font-weight: 500;
  font-family: "Satoshi";
  padding-bottom: 60px;
}
.white-menu-section .white-menu-grid .white-menu-left .contact-form-div .contact-description .bold-text {
  font-weight: 900;
}
.white-menu-section .white-menu-grid .white-menu-left .contact-form-div .icons-and-button {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  -moz-column-gap: 20px;
       column-gap: 20px;
}
.white-menu-section .white-menu-grid .white-menu-left .contact-form-div .icons-and-button .deco-icons .deco-icon:nth-child(2),
.white-menu-section .white-menu-grid .white-menu-left .contact-form-div .icons-and-button .deco-icons .deco-icon:nth-child(3) {
  margin-left: 20px;
}
.white-menu-section .white-menu-grid .white-menu-left .contact-form-div .icons-and-button .deco-icons img {
  height: 48px;
}
.white-menu-section .white-menu-grid .white-menu-right {
  grid-column: 2/3;
  margin-top: 200px;
}
.white-menu-section .white-menu-grid .white-menu-right .white-menu-right-grid {
  display: grid;
  grid-template-columns: 10% 1fr;
}
.white-menu-section .white-menu-grid .white-menu-right .number {
  grid-column: 1/2;
  display: flex;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.white-menu-section .white-menu-grid .white-menu-right .number span {
  font-size: 14px;
  font-weight: 500;
}
.white-menu-section .white-menu-grid .white-menu-right .title {
  grid-column: 2/3;
  padding-top: 20px;
  padding-bottom: 20px;
}
.white-menu-section .white-menu-grid .white-menu-right .title h4 {
  font-size: 30px;
  font-weight: 500;
  display: inline;
}
.white-menu-section .white-menu-grid .white-menu-right .title h4:hover {
  cursor: pointer;
}
.white-menu-section .white-menu-grid .white-menu-right .title .arrow-right {
  display: inline;
  padding-left: 24px;
  transition: all 500ms;
}
.white-menu-section .white-menu-grid .white-menu-right .title .arrow-right:hover {
  cursor: pointer;
}
.white-menu-section .white-menu-grid .white-menu-right .title .arrow-right-active {
  transform: rotateZ(90deg) translate(-25%, -50%);
  transition: all 500ms;
}
.white-menu-section .white-menu-grid .white-menu-right .title .description-parent {
  max-width: 590px;
  height: 0px;
  overflow: hidden;
  transition: all 700ms;
}
.white-menu-section .white-menu-grid .white-menu-right .title .description-parent .description-child {
  border-bottom: 1px solid black;
  padding-bottom: 38px;
}
.white-menu-section .white-menu-grid .white-menu-right .title .description-parent p {
  padding-bottom: 38px;
}
.white-menu-section h3 {
  color: #000000;
  font-size: 50px;
  line-height: 72px;
  font-weight: 400;
}
.white-menu-section p {
  color: #000000;
  margin-top: 42px;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
}
.white-menu-section span {
  color: #000000;
}
.white-menu-section h4 {
  color: #000000;
}

@media (max-width: 1500px) {
  .white-menu-section h3 {
    font-size: 40px;
    line-height: 50px;
  }
  .white-menu-section .white-menu-grid .white-menu-right .title h4 {
    font-size: 22px;
  }
  .white-menu-section .white-menu-grid .white-menu-left .contact-form-div .contact-description {
    font-size: 20px;
  }
}
@media (max-width: 1300px) {
  .white-menu-section h3 {
    font-size: 30px;
    line-height: 40px;
  }
  .white-menu-section p {
    font-size: 10px;
  }
  .white-menu-section .white-menu-grid .white-menu-left .contact-form-div .contact-description {
    width: 90%;
    font-size: 20px;
    line-height: 25px;
  }
  .white-menu-section .white-menu-grid .white-menu-left .contact-form-div .contact-form .deco-icons {
    gap: 18px;
  }
  .white-menu-section .white-menu-grid .white-menu-right .title h4 {
    font-size: 20px;
  }
}
@media (max-width: 1024px) {
  .white-menu-left {
    grid-column: 1/3 !important;
    grid-row: 2/3;
  }
  .white-menu-right {
    grid-column: 1/3 !important;
    grid-row: 1/2;
  }
  .white-menu-section .white-menu-grid .white-menu-right {
    margin-top: -100px;
    margin-bottom: 100px;
  }
  .white-menu-section .white-menu-grid .white-menu-left .contact-form-div {
    position: relative;
    bottom: 0;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .white-menu-section {
    width: 100vw;
    padding: 150px 20px 50px 20px;
  }
  .white-menu-section .white-menu-grid .white-menu-right .number span {
    font-size: 11px;
  }
  .white-menu-section .white-menu-grid .white-menu-right .title h4 {
    font-size: 14px;
  }
  .white-menu-section .white-menu-grid .white-menu-right .title .arrow-right {
    width: 46px;
  }
  .white-menu-section h3 {
    font-size: 24px;
    line-height: 30px;
  }
  .white-menu-section .white-menu-grid .white-menu-left .contact-form-div .contact-form {
    width: 100%;
  }
  .white-menu-section .white-menu-grid .white-menu-left .contact-form-div .contact-description {
    width: 100%;
    font-size: 16px;
    line-height: 22px;
  }
  .white-menu-section .white-menu-grid .white-menu-left {
    margin-top: -50px;
  }
  .white-menu-section .white-menu-grid .white-menu-left .contact-form-div .contact-form .deco-icons img {
    width: 27px;
  }
  .white-menu-section .white-menu-grid .white-menu-left .contact-form-div .icons-and-button {
    width: 100%;
  }
  .white-menu-section .white-menu-grid .white-menu-left .contact-form-div .icons-and-button {
    flex-direction: column;
    gap: 20px;
  }
  .white-menu-section .white-menu-grid .white-menu-left .contact-form-div .icons-and-button .deco-icons img {
    height: 32px;
  }
}/*# sourceMappingURL=WhiteMenuSection.css.map */